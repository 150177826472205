import { useState, useEffect, useContext } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.css";
import DetailHeader from "./DetailHeader";
import DetailFooter from "../Shared/DetailFooter";
import { DetailTitle } from "./DetailTitle";
import { DiseaseSurvey } from "./PageContent/DiseaseSurvey";
import {Route, Routes, Navigate, useNavigate} from "react-router-dom";
import { Verticals } from "./PageContent/Verticals";
import DoComponent from "./DetailContent/Do";
import SayComponent from "./DetailContent/Say";
import SeeComponent from "./DetailContent/See";
import FeelingsFlowQuestion from "../PublicHealth/FeelingsFlowQuestion";
import { BackgroundContext } from "../../context/background.context";
import ProcessScreen from "../PublicHealth/EmotionalHealth/ProcessScreen";
import { API } from "aws-amplify";
import ScanComponent from "./DetailContent/Scan";
import { Model } from "survey-core";
import fftJSON from "../PublicHealth/FeelingsFlowTrackerForms/FeelingsFlowJSON";

// import { dynamoDBFetch, useGetUserDataQuery } from "../../store/emotionalHealthSlice";

function LearnDetail(props) {
  // const [showVerticals, setShowVerticals] = useState(false);
  // const [page, setPage] = useState("Community Health");
  const [reachedLimit, setReachedLimit] = useState(false);
  const [glossaryOpen, setGlossaryOpen] = useState(false);
  const { blurBackground, isModalOpen } = useContext(BackgroundContext);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [navPage, setNavPage] = useState({
      do_page: true,
      say_page: false,
      see_page: false,
      scan_page: false
  })
  const [dropdownOpen, setDropdownOpen] = useState({
    footer: false,
    expand: false,
  });
  const location = useLocation();

    const optionsB = {
    community: ["Location"],
    emotional: ["Today","This Week", "This Month", "Select Date/Range"],
  }

  const comingSoonOptionsB = {
    community: ["Disease", "Transmission Type", "Demographics", "Advanced"],
    emotional: ["Thoughts", "Feelings","Body Sensations", "Actions"],
  };



    const [navPageStyle, setNavPageStyle] = useState({
        background: "#F76E2D",
        boxShadow: "0px 4px 4px 0px #00000040",
        color: "white"
    })
  const [opacityDark, setOpacityDark] = useState(false);
  


  //disease is specific to community health page


  // const [emotional] = useOutletContext()
  const {
    options,
    countyData,
    countyLabel,
    stateData,
    stateLabel,
    countryData,
    countryLabel,
    comingSoonOptions,
    headerTitle,
    minuteCardOneHeader,
    minuteCardOneText,
    minuteCardTwoHeader,
    minuteCardTwoText,
    sayComponentText,
    saySectionLabel,
    tokenPayload,
    newNavPage,
    dropdownOpenState = {footer:false,expand:false},
  } = location.state || {};

  const {
    savedFFTData,
    setSavedFFTData,
    newEmotionalData,
    setNewEmotionalData
  } = props;

  const [showVerticals, setShowVerticals] = useState(false);
  const [page, setPage] = useState('do-say-see');

  //disease is specific to community health page
  const [disease, setDisease] = useState(null);

  //For calendar - Filter data based on date
  const [value, onChange] = useState(new Date());

  const [emotionalData, setEmotionalData] = useState({});
  const [response, setResponse] = useState({});
  const [reload, setReload] = useState(false)

  const navigate = useNavigate()

  const extractMostRecent = (data) => {
    // console.log('most recent', data)
    return data.payload.Items[0].survey;
  }

 //TODO: Filter data based on date

  //Code block to convert Date value to month day style (Feb 1)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  // make this a question

  let month = ''
  let month2 =''
  let day =''
  let day2 =''
  let date1 =''
  let date2 =''

  let selectedDate = date1 + ' - ' + date2

  const setDate = ()=>{
   month = value[0]?.getMonth()
   month2 = value[1]?.getMonth()
   day = value[0]?.getDate()
   day2 = value[1]?.getDate()

   date1 = months[month] + ' ' + day
   date2 = months[month2] + ' ' + day2

   selectedDate = date1 + ' - ' + date2
  }

  const filterByDate = (data) => {

    let date = ''
    setDate()
    const filter = data.payload.Items.forEach(el => {
    const dataReformatted = new Date(el?.dateAdded)
      // const month = dataReformatted.getMonth()
      // const day = dataReformatted.getDate()
    date = months[month] + ' ' + day

      // If calendar selected date matches date from DB, update data for it.
      // console.log('filtering', date, value)
      if (date1 === date) {
        setEmotionalData(el.survey)
        // console.log('emotional data new', emotionalData)
      }
      return date
    });

  }

      const navPageToggle= (name)=>{
        const newToggle = {
            do_page: false,
            say_page: false,
            see_page: false,
            scan_page: false
        }
        setNavPage({...newToggle, [`${name}`]: true})
    }

    useEffect(() => {
      if (location.state?.navPage !== undefined) {
        setNavPage(location.state.navPage)
      }
    }, [location?.state?.navPage])

    const handleSurveyState = (attribute, newVal) => {
      setSavedFFTData((oldData) => {
        return {
          ...oldData,
          [attribute]: newVal
        }
      })
    };

    useEffect(() => {
      if (savedFFTData.surveyData && savedFFTData.surveyData.question1){
        console.log("SurveyData:", savedFFTData.surveyData)
        console.log("savedFFTData.fftSurvey.data:", savedFFTData.fftSurvey.data)
        const text_num = savedFFTData.surveyData.question1.replace(/[^0-9]/g,"")
  
        const AF_page_icon = savedFFTData.fftSurvey.getQuestionByName('question1').jsonObj.choices[text_num-1];
        const AF_page_icon2 = savedFFTData.fftSurvey.getQuestionByName('Associated Feelings Icon');
        AF_page_icon2.html = `<div class="AssociatedEmoticonBox">${AF_page_icon.text}</div>`;
        handleSurveyState("reRender",true);
      }
    }, [savedFFTData.currentPageNo, savedFFTData.reRender]);

  //TODO: Dynamically fetch via  by entering tokenPayload
  //  const {data: pFeelings} = dynamoDBFetch.endpoints.getPrimaryFeelings.useQuery(tokenPayload.sub)
  //  const {data: aFeelings} = dynamoDBFetch.endpoints.getAssociatedFeelings.useQuery(tokenPayload.sub)

  useEffect(()=> {
    setDropdownOpen(dropdownOpenState);
  }, [location.state]);

    useEffect(() => {

        if (location.pathname === "/learnmore" || location.pathname === "/learnmore/survey") {
            setNavPageStyle({
                background: "#EA4C01",
                boxShadow: "0px 4px 4px 0px #00000040",
                color: "white"

            })
        } else {
            setNavPageStyle({
                background: "#F76E2D",
                boxShadow: "0px 4px 4px 0px #00000040",
                color: "white"
            })

        }

        if (newNavPage && newNavPage === "See") {
            setNavPage({
                do_page: false,
                say_page: false,
                see_page: true,
                scan_page: false
            })
        }

    }, [location.pathname]);


  useEffect(() => {
    const DynamoFetch = () => {
      // const {tokenPayload} =props
      // console.log('tokenPayload db', tokenPayload)
      console.log(JSON.stringify(tokenPayload?.sub));
      const userId = tokenPayload?.sub;
      // TODO: integrate limit count to dynamically set limit on M's API (civilience)
      const limit = '4'

      if (!userId) {
        return;
      }

      try {
        API.get("civAPI", `/emotional/retrieve/${userId}/`).then((response, request) => {
          // console.log('response from civAPI call', response)
          if (!response.error) {
            const mostRecent = extractMostRecent(response);
            filterByDate(response)
            // setEmotionalData(mostRecent);
            setResponse(response)
          } else {
            setEmotionalData(false);
          }
        });
      } catch (error) {
        console.log('response from public: none', error);
      }

    }
    DynamoFetch()
  }
    , [page, value])

    const communityHealthInfo = {
        headerTitle: "Community Health",
        options: location.pathname === "/emotionalhealth/process" ? options: options ? options: optionsB,
        comingSoonOptions: location.pathname === "/emotionalhealth/process" ? comingSoonOptions: comingSoonOptions ? comingSoonOptions: comingSoonOptionsB,
        countyData,
        countyLabel,
        stateData,
        stateLabel,
        countryData,
        countryLabel,
        // disease: disease,
        // setDisease:setDisease,
        // page: page,
        // setPage:setPage,
        // showVerticals: showVerticals,
        // setShowVerticals: setShowVerticals,
        minuteCardOneHeader: "My Guidelines",
        minuteCardOneText: "Know what guidelines apply to you",
        minuteCardTwoText: "Know guidelines and your personal risk of catching & spreading",
        minuteCardTwoHeader: "My Risk",
        sayComponentText: " Do you think the Covid-19 pandemic is over? Why or why not?",
        saySectionLabel: "VIEW RISK BY",
        tokenPayload
    }

    const diseaseInfo = {
      disease: disease,
      setDisease: setDisease
    }

    useEffect(() => {
        if (reload) {
            window.location.reload()
        }

    }, [reload])


    const externalNavToggle = (page) => {
      navigate('/learnmore', { state: location.state } )
      navPageToggle(page)
      setDisease(null)
    }

  return (
    
    <div className="LearnDetail">    
      {/* {console.log("tokenPayload: "+tokenPayload.sub)} */}
      {reachedLimit || glossaryOpen || opacityDark ? <div className="LearnDetail_padding" /> : null}
      <DetailHeader parentPath={location.pathname} setDropdownOpen={setDropdownOpen} dropdownOpen={dropdownOpen} tokenPayload={tokenPayload} communityHealthInfo={communityHealthInfo} variant={true} 
      options = {options}
      countyData = {countryData}
      countyLabel = {countryLabel}
      stateData = {stateData}
      stateLabel = {stateLabel}
      countryData = {countryData}
      countryLabel = {countryLabel}
      comingSoonOptions = {comingSoonOptions}
      headerTitle = {headerTitle}
      minuteCardOneHeader = {minuteCardOneHeader}
      minuteCardOneText = {minuteCardOneText}
      minuteCardTwoHeader = {minuteCardTwoHeader}
      minuteCardTwoText = {minuteCardTwoText}
      sayComponentText = {sayComponentText}
      saySectionLabel = {saySectionLabel}
      newNavPage = {newNavPage}
      savedFFTData={savedFFTData}
      setSavedFFTData={setSavedFFTData}
      navPage = {navPage}
    />
      <DetailTitle communityHealthInfo={communityHealthInfo} diseaseInfo = {diseaseInfo}/>
      <Routes>
          <Route exact path="" element={<>
              <div id="DO-SAY-SEE" style={{display: showVerticals ? "none" : "block"}}>

                  <div className={location.pathname === "/emotionalhealth" ? "dosaysee_navigation": "dosaysee_navigation dosaysee_navigation_id"}>
                      <ul>
                          <li onClick={() => navPageToggle("do_page")}
                              style={navPage.do_page ? navPageStyle : null}>DO</li>
                          <li onClick={() => navPageToggle("say_page")}
                              style={navPage.say_page ? navPageStyle : null}>SAY</li>
                          <li onClick={() => navPageToggle("see_page")}
                              style={navPage.see_page ? navPageStyle : null}>SEE</li>
                          <li onClick={() => navPageToggle("scan_page")}
                              style={navPage.scan_page ? navPageStyle : null}>SCAN</li>
                      </ul>
                  </div>
                  {navPage.do_page &&
                      <DoComponent
                          disease={disease}
                          headerTitle={headerTitle}
                          minuteCardOneHeader={minuteCardOneHeader}
                          minuteCardOneText={minuteCardOneText}
                          minuteCardTwoHeader={minuteCardTwoHeader}
                          minuteCardTwoText={minuteCardTwoText}
                          setDisease={setDisease}
                          setPage={setPage}
                          tokenPayload={tokenPayload}
                          communityHealthInfo={communityHealthInfo}
                          savedFFTData={savedFFTData}
                      />

                  }
                  {navPage.say_page &&
                      <SayComponent
                          sayComponentText={sayComponentText}
                          tokenPayload = {tokenPayload}
                      />
                  }

                  {navPage.see_page &&
                      <SeeComponent
                          headerTitle={headerTitle}
                          countyData={countyData}
                          countyLabel={countyLabel}
                          stateData={stateData}
                          stateLabel={stateLabel}
                          countryData={countyData}
                          countryLabel={countryLabel}
                          options={options}
                          comingSoonOptions={comingSoonOptions}
                          saySectionLabel={saySectionLabel}
                          tokenPayload={tokenPayload}
                          page={page}
                          value={value}
                          emotionalData={newEmotionalData}
                          savedFFTData={savedFFTData}
                      />
                  }

        {navPage.scan_page &&
  <ScanComponent />
  }



                  {/* TODO: Process screen moved here for testing. Move Process screen back to position.  */}
                  {/* <ProcessScreen
            options={options}
            value={value}
            onChange={onChange}
            setPage={setPage}
            emotionalData={emotionalData}
            date1={date1}
            date2={date2}
            selectedDate={selectedDate}
          /> */}
              </div>
          </>}>
          </Route>
                {/*{(page === "survey" & headerTitle === "Community Health") ? (*/}
          <Route exact path="survey" element={<>
                <div className={location.pathname === "/emotionalhealth" ? "dosaysee_navigation": "dosaysee_navigation dosaysee_navigation_id"}>
                    <ul>
                        <li onClick={() => externalNavToggle("do_page")}
                            style={navPage.do_page ? navPageStyle : null}>DO</li>
                        <li onClick={() => externalNavToggle("say_page")}
                            style={navPage.say_page ? navPageStyle : null}>SAY</li>
                        <li onClick={() => externalNavToggle("see_page")}
                            style={navPage.see_page ? navPageStyle : null}>SEE</li>
                        <li onClick={() => externalNavToggle("scan_page")}
                            style={navPage.scan_page ? navPageStyle : null}>SCAN</li>
                    </ul>
                </div>

              <DiseaseSurvey
                  disease={disease}
                  setDisease={setDisease}
                  showVerticals={showVerticals}
                  page={page}
                  setPage={setPage}
                  tokenPayload={tokenPayload}
                  countyData={countyData}
                  countyLabel={countyLabel}
                  stateData={stateData}
                  stateLabel={stateLabel}
                  countryData={countyData}
                  countryLabel={countryLabel}/>
                  </>
          }>
          </Route>
          <Route exact path="fft" element={
              <FeelingsFlowQuestion
                  headerTitle={headerTitle}
                  tokenPayload={tokenPayload}
                  setPage={setPage}
                  reachedLimit={reachedLimit}
                  setEmotionalData={setNewEmotionalData}
                  setReachedLimit={setReachedLimit}
                  glossaryOpen={glossaryOpen}
                  setGlossaryOpen={setGlossaryOpen}
                  isAlertOpen={isAlertOpen}
                  setIsAlertOpen={setIsAlertOpen}
                  opacityDark={opacityDark}
                  options={options}
                  comingSoonOptions={comingSoonOptions}
                  setOpacityDark={setOpacityDark}
                  page={page} 
                  savedFFTData={savedFFTData}
                  setSavedFFTData={setSavedFFTData}
                  emotionalData={newEmotionalData}
                  communityHealthInfo={communityHealthInfo}
                  />

          }>
        
          </Route>
          
          <Route exact path="process"
                  element={<ProcessScreen setSavedFFTData={setSavedFFTData} savedFFTData={savedFFTData} options={options} value={value} onChange={onChange} setPage={setPage}
                                          emotionalData={newEmotionalData} setReload={setReload} tokenPayload={tokenPayload} communityHealthInfo={communityHealthInfo} setNavPage={setNavPage}/>}>
          </Route>
      </Routes>



      {showVerticals && <Verticals />}

      <DetailFooter setDropdownOpen={setDropdownOpen} dropdownOpen={dropdownOpen} />


      {isAlertOpen && (
        <div className="alert-div alert-message" style={{ position: "absolute", top: "530px", border: "none" }}>
          <span>You may only select 12</span>
          <button className="close-button" style={{ border: "none", marginRight: 5, marginTop: -8 }} onClick={() => { setIsAlertOpen(false); setReachedLimit(false) }}>
            X
          </button>
        </div>
      )}
    </div>
  );
}
export default LearnDetail;