import React, { useRef, useState, useEffect} from "react";
import { MdEdit } from 'react-icons/md';
import { FaChevronRight, FaRegTrashAlt } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { getZipcodeDetails } from "../Shared/ZipcodeSearch";
import DropdownButton from "../Shared/DropdownButton";
import axios from 'axios';

const Geolocation = ({
    expand,
    pageBook,
    closePage,
    setPage,
    DropdownHeader,
    handleButtonActivity,
    handleReset,
    buttonActivity, 
    confirmSettingRef,
    registeredLocation,
    toggleDialog,
    activePrimaryLocation,
    activePrimaryLocationString,
    setActivePrimaryLocation,
    setActivePrimaryLocationString
}) => {

    const [labelInput, setLabelInput] = useState("")
    const [zipResults, setZipResults] = useState([])
    const [zipResultStrings, setZipResultStrings] = useState([])
    const [relevantLocations, setRelevantLocations] = useState([])
    const [activeRelevantLocation, setActiveRelevantLocation] = useState({})
    const [activeRelevantLocationString, setActiveRelevantLocationString] = useState("")
    const [editingIndex, setEditingIndex] = useState(null)
    const [sortedZipResults, setSortedZipResults] = useState([])
    const inputRef = useRef()
    const relevantRef = useRef()
    const labelRef = useRef()
    const [zipExists, setZipExists] = useState(false);
    const activeLabel = useRef("")

    

    const [RoamingLocations, setRoamingLocations] = useState([])
    useEffect(() => {
        setRoamingLocations([
            getZipcodeDetails("97009")[0],
            getZipcodeDetails("99732")[0],
            getZipcodeDetails("96778")[0],
            getZipcodeDetails("66701")[0],
            getZipcodeDetails("11560")[0],
            getZipcodeDetails("11932")[0],
        ])
    }, [])

    useEffect(() => {
        if (activePrimaryLocationString != "") {
            inputRef.current.value = activePrimaryLocationString.split(',')[0].trim();
        }

        if (activeRelevantLocationString != "") {
            relevantRef.current.value = activeRelevantLocationString.split(',')[0].trim();
            buttonActivity.addRelevant = true;
        }

        if (activeLabel.current != "") {
            labelRef.current.value = activeLabel.current
        }
    }, [activePrimaryLocation, activeRelevantLocationString, activeLabel.current])

    const controllerRef = useRef(null)
    //connects to zip code api to get location details
    const getLocations = async (zipcode) => {
        try {
            if(controllerRef.current) {
                controllerRef.current.abort()
            }
            controllerRef.current = new AbortController()

            const res = await axios.get(`https://ehomoi5b95.execute-api.us-east-1.amazonaws.com/dev/zipcode/getAllPlacesByZipcodeStartWith`, {
                params: { zipcodeStartWith: zipcode },
                signal: controllerRef.current.signal
            });
            console.log(res.data);
            const zipcodes = res.data.places;

            let locationArray = [];
            for (const zip of zipcodes) {
                for (const place of zip.places) {
                    const location = {...place, zipcode: zip.zipcode};
                    locationArray.push(location);
                }
            };
            setZipExists(true);
            setZipResults(locationArray);
        }
        catch (error) {
            console.error(error);
            setZipExists(false);
            setZipResultStrings(['Please enter a valid zipcode']);
        }
    }


    useEffect(() => {
        //TODO: insert sorting logic here
        let locationArray = [];
        const sortedResults = [...zipResults].sort((a, b) => {
            if (a.zipcode < b.zipcode) return -1;
            if (a.zipcode > b.zipcode) return 1;
    
            if (a.pn < b.pn) return -1;
            if (a.pn > b.pn) return 1;
    
            if (a.adm3 < b.adm3) return -1;
            if (a.adm3 > b.adm3) return 1;
    
            if (a.adm2 < b.adm2) return -1;
            if (a.adm2 > b.adm2) return 1;
    
            if (a.adm1 < b.adm1) return -1;
            if (a.adm1 > b.adm1) return 1;
    
            if (a.cc < b.cc) return -1;
            if (a.cc > b.cc) return 1;
    
            return 0;
        });
        setSortedZipResults(sortedResults)
        //console.log("console"+JSON.stringify(zipResults));
        //console.log("sorted: "+JSON.stringify(sortedResults));
        for (const location of sortedResults) {
            const country = location.cc;
            //formatting the location details from smallest to largest
            locationArray.push
                (`${location.zipcode},
                ${location.pn? location.pn + ',' : ''} 
                ${location.adm3?  location.adm3 + ',' : ''}
                ${location.adm2? location.adm2 + ',' : ''}
                ${location.adm1? location.adm1 + ',' : ''} 
                ${country}`);
        };
        setZipResultStrings(locationArray);
    }, [zipResults]);


    const handleLocationSelect = async (index, section) => {
        if (section === "primary") {
            setActivePrimaryLocation(sortedZipResults[index]);
            setActivePrimaryLocationString(zipResultStrings[index]);
        } else if (section === "relevant") {
            setActiveRelevantLocation(zipResults[index]);
            setActiveRelevantLocationString(zipResultStrings[index]);
        }
    };

    const changeLabel = (data) => {
        setRelevantLocations((prevItems) => {
            return prevItems.map((item, i) => {
                if (data != null && i == editingIndex) {
                    return data;
                } 
                return item;
            })
        })
        setEditingIndex(null)
        setActiveRelevantLocationString("")
        setActiveRelevantLocation({})
        activeLabel.current = ""
    }
    
    return (
        <>
            {expand.geolocation && 
                    <>
                        <div className="dropdownSection">
                            <div className="dropdownPanel" id="geolocationPanel">
                                <div className="dropdownHeader" id="geolocationHeader">
                                    {pageBook.geolocation===1 && 
                                        <ul className="headerNav">
                                            <li className="firstPage"><p className="dropdownChevron">&lt;</p></li>
                                            <li><p>Geolocation</p></li>
                                            <li><button onClick={() => {closePage()}} className="dropdownClose"><p>-</p></button></li>
                                        </ul>
                                    }
                                    {pageBook.geolocation===2 && <DropdownHeader parent="geolocation" title="Registered Location" parentPage={1}/>}
                                    {pageBook.geolocation===3 && <DropdownHeader parent="geolocation" title="Relevant Location" parentPage={(relevantLocations.length > 0) ? 6 : 1} onClick={() => {
                                        changeLabel(null)
                                    }}/>}
                                    {pageBook.geolocation===4 && <DropdownHeader parent="geolocation" title="Roaming Locations" parentPage={1}/>}
                                    {pageBook.geolocation===5 && <DropdownHeader parent="geolocation" title="Verification" parentPage={2}/>}
                                    {pageBook.geolocation===6 && <DropdownHeader parent="geolocation" title="Relevant Locations" parentPage={1} onClick={() => setEditingIndex(null)}/>}
                                    {pageBook.geolocation===7 && <DropdownHeader parent="geolocation" title="" parentPage={1}/>}
                                </div>
                                

                                <div className="dropdownBody">
                                    {pageBook.geolocation===1 && 
                                        <div className='dropdownBody'>
                                            <li><p>Registered:</p><button className="styledButton" onClick={() => {setPage("geolocation", 2)}}>{registeredLocation? registeredLocation : 'Loading...'} <MdEdit/></button></li>
                                            <li><p>Relevant:</p><button className="styledButton" onClick={() => {
                                                if (relevantLocations.length === 0) {
                                                    setPage("geolocation", 3)
                                                } else {
                                                    setPage("geolocation", 6)
                                                }
                                            }}>{relevantLocations.length === 0 ? (
                                                <FiPlus/>
                                            ) : (
                                                <>
                                                    {relevantLocations[0].label}
                                                    <FaChevronRight/>
                                                </>
                                            )}</button></li>
                                            <li><p>Roaming:</p><button className="styledButton" onClick={() => {setPage("geolocation", 4)}}>{RoamingLocations[0]} <FaChevronRight/></button></li>
                                        </div>
                                    }
                                    {pageBook.geolocation===2 && 
                                        <div className='dropdownBody'>
                                            <form id="editPrimaryForm" onSubmit={(e) => {
                                                e.preventDefault();
                                                //setPage("geolocation", 5);
                                                toggleDialog(confirmSettingRef)
                                                //handleReset("editPrimary");
                                            }}>
                                                <div className={"zipcodeDropdown"}> 
                                                    <DropdownButton
                                                        ButtonAllTextColor={"black"}
                                                        ButtonPrimaryTextColor={'black'}
                                                        ButtonPrimaryBackground={'#f8f9fa'}
                                                        ButtonAllBackground={"#f8f9fa"}
                                                        ButtonFunctionChange={false}
                                                        ButtonAllWidth={'17.6rem'}
                                                        ButtonPrimaryWidth={'150px'}
                                                        ButtonPrimaryIcon={'block'}
                                                        BoxPosition={['30px', '19px']}
                                                        FontSize={'11px'}
                                                        Overflow={true}
                                                        listHoverColor={'#00286E'}
                                                        ButtonBoxBorder={true}
                                                        ButtonAllRadius={'8px'}
                                                        ButtonPrimaryRadius={'20px'}
                                                        ButtonAllHeight={'25px'}
                                                        ButtonCharList={zipResultStrings}
                                                        maxPrimaryWidth={'300px'}
                                                        Function={function (e) {
                                                            if(zipExists) {
                                                                console.log(e.textContent);
                                                                handleLocationSelect(e.value, "primary");
                                                                handleButtonActivity("editPrimaryForm", "editPrimary");
                                                            }
                                                    }}
                                                        primaryBox={
                                                            <input
                                                                // className={(status === 'fulfilled' && !data) && 'error'}
                                                                // ref={inputRef}
                                                                type="text"
                                                                placeholder="Postal/Zip Code"
                                                                ref={inputRef}
                                                                //value={inputValue}
                                                                onClick={() => {setActivePrimaryLocation({})}}
                                                                onChange={e => {
                                                                    handleReset("editPrimary")
                                                                    getLocations(e.target.value);
                                                                }}
                                                            />

                                                        }
                                                        
                                                    />
                                                </div>
                                                <br/><button className={buttonActivity.editPrimary ? " submitLinkActive" : " submitLinkInactive"} type="submit" disabled={activePrimaryLocationString === ""}>Edit</button>
                                            </form>
                                            
                                        </div>
                                    }
                                    {pageBook.geolocation===3 && 
                                        <>
                                            <div className='dropdownBody'>
                                                <form id="addRelevantForm" onSubmit={(e) => {
                                                    e.preventDefault()
                                                    const formData = new FormData(e.target)
                                                    const data =  {
                                                        label: formData.get('label'),
                                                        zip: activeRelevantLocationString,
                                                    }

                                                    if (data.zip) {
                                                        if (editingIndex == null) {
                                                            setRelevantLocations([...relevantLocations, data])
                                                        } else {
                                                            changeLabel(data)
                                                        }
                                                        setPage("geolocation", 6)
                                                    } else {
                                                        console.log("Invald Zip Code!")
                                                    }
                                                    handleReset("addRelevant");

                                                }}>
                                                    <input 
                                                        type="text" 
                                                        minLength={1} 
                                                        id="label" 
                                                        name="label" 
                                                        placeholder="Label" required 
                                                        ref={labelRef} 
                                                        style={{width: '300px', height: '40px', fontSize:'16px'}}
                                                        onKeyDown={() => activeLabel.current = ""}
                                                        onChange={(e) => {setLabelInput(e.target.value)}}
                                                        >                                                      
                                                    </input>
                                                    <div className={"zipcodeDropdown"}> 
                                                        <DropdownButton
                                                            ButtonAllTextColor={"black"}
                                                            ButtonPrimaryTextColor={'black'}
                                                            ButtonPrimaryBackground={'#f8f9fa'}
                                                            ButtonAllBackground={"#f8f9fa"}
                                                            ButtonFunctionChange={false}
                                                            ButtonAllWidth={'17.6rem'}
                                                            ButtonPrimaryWidth={'150px'}
                                                            ButtonPrimaryIcon={'block'}
                                                            BoxPosition={['30px', '19px']}
                                                            FontSize={'11px'}
                                                            Overflow={true}
                                                            listHoverColor={'#00286E'}
                                                            ButtonBoxBorder={true}
                                                            ButtonAllRadius={'8px'}
                                                            ButtonPrimaryRadius={'20px'}
                                                            ButtonAllHeight={'25px'}
                                                            ButtonCharList={zipResultStrings}
                                                            Function={function (e) {
                                                                if(zipExists) {
                                                                    handleLocationSelect(e.value, "relevant");
                                                                    handleButtonActivity("addRelevantForm", "addRelevant");
                                                                }
                                                                
                                                            }}
                                                            primaryBox={
                                                                <input
                                                                    // className={(status === 'fulfilled' && !data) && 'error'}
                                                                    // ref={inputRef}
                                                                    type="text"
                                                                    name="zip"
                                                                    placeholder="Postal/Zip Code"
                                                                    ref={relevantRef}
                                                                    //value={inputValue}
                                                                    onClick={() => {setActiveRelevantLocation({})}}
                                                                    onKeyUp={e => {
                                                                        getLocations(e.target.value);
                                                                        handleReset("addRelevant")
                                                                    }}
                                                                />

                                                            }
                                                            
                                                        />
                                                    </div>
                                                    <br/><button className={(buttonActivity.addRelevant && labelInput.length > 0) ? " submitLinkActive" : " submitLinkInactive"} type="submit">{editingIndex != null ? "Edit" : "Add"}</button>
                                                </form>
                                            </div>
                                        </>
                                    }
                                    {pageBook.geolocation===4 && 
                                        <div className='dropdownBody'>
                                            {RoamingLocations.map((location) => (
                                                <ul style={{alignItems: 'normal', marginLeft: '10px'}}>{location}</ul>
                                            ) )}
                                        </div>
                                    }
                                    {pageBook.geolocation===6 && 
                                        <div className='dropdownBody'>
                                            {relevantLocations.map((location, index) => (
                                                <li className="spread" key={index} style={{ justifyContent: 'center' }} >
                                                {(editingIndex != index) ? 
                                                (
                                                    <button className="spreadItems spreadItemsGeo" onClick={() => setEditingIndex(index)}>
                                                        <p className="invisible">......</p>
                                                        <p className="unbolded"><span>{location.label}</span><br/>{location.zip}</p>
                                                        <p className="chevronRight unbolded"><span>&lt;</span></p>
                                                    </button>
                                                )
                                            :
                                                (
                                                <button className="spreadItems spreadItemsGeo" onClick={() => {setEditingIndex(null)}}>
                                                    <p className="unbolded" style={{width: '60%'}}><span>{location.label}</span><br/>{location.zip}</p>
                                                    <p className="chevronRight unbolded"><span>&gt;</span></p>
                                                    <p className="spreadItemButton spreadItemEdit scaleIcons" onClick={(e) => {
                                                        e.stopPropagation()
                                                        buttonActivity.addRelevant = true;
                                                        setActiveRelevantLocationString(location.zip)
                                                        activeLabel.current = location.label
                                                        setPage("geolocation", 3)
                                                    }}><MdEdit/></p>
                                                    <p  className="spreadItemButton spreadItemDelete scaleIcons"  onClick={() => {
                                                        setRelevantLocations((prevLocations) => {
                                                            const newLocations = prevLocations.filter((_, i) => i !== index)
                                                            if (newLocations.length < 1) {
                                                                setPage('geolocation', 1);
                                                            }
                                                            return newLocations; 
                                                        });

                                                        }}><FaRegTrashAlt/></p>
                                                    <p></p>
                                                </button>
                                                )
                                            }
                                            
                                        </li>
                                        
                                            ))}
                                            <li style={{ justifyContent: 'center' }}>
                                                <button style={{ width: '100%' }} onClick={() => {
                                                    setEditingIndex(null)   
                                                    setPage("geolocation", 3) 
                                                }}>
                                                    <h1>+ New</h1>
                                                </button>
                                            </li>
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>    
                    </>
                }
        </>
    )
}

export default Geolocation