import { useEffect } from "react";
import feelings from "../../../../images/ftt-process/feelings.jpg";
import { motion } from "framer-motion";

const Feelings = ({ emotionalData, findEmotionIcon}) => {
  console.log(emotionalData)
  const newdata = ["Renewed", "Enthusiastic"]
  useEffect(()=>{    
    const emoIcon=findEmotionIcon().text
    const emotionBox = document.querySelector(".AssociatedEmoticonBoxProcess");

    console.log("queried emotionbox:",emotionBox);
    console.log("emotional Icon:",emoIcon)

    emotionBox.innerHTML = emoIcon;
  },[])
  return (
    <motion.div
      className="myftt"
      initial={{ x: 250, opacity: 0 }}
      animate={{ x: 0, opacity: 1, type: "tween" }}
      // transition={{delay:0.2}}
    >
      {/*<div className="header">*/}
      {/*  <h2>My Feelings Flow Tracker</h2>*/}
      {/*  <div className="colorLine" style={{ width: "100%", height: "6px" }} />*/}
      {/*</div>*/}
      <div
        className="process-container"
        style={{ backgroundImage: `url(${feelings})`, backgroundSize: "cover" }}
      >
        <div className="header-container">
          <p>... gave rise to these <b>Feeling(s)</b> in me:</p>
        </div>
        <div className="body-container">
          <div className="body-description">
            {/*<p>... gave rise to these feelings in me:</p>*/}
          </div>
          <div className="body-summary" style={{marginTop: "-300px"}}>

            <div class="AssociatedEmoticonBox AssociatedEmoticonBoxProcess">
              {emotionalData.q1}
            </div>
            <hr/>
            <div className={"associated-process-box"}>
              {
                emotionalData.q2.map((el) => {
                      if (emotionalData.q2.length === 1) {
                        return (
                            <span className={"associated-process-single"}>{el}</span>
                        )
                      } else {
                        return (<span>{el}</span>)
                      }
                    }
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default Feelings;
