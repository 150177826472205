import React, { useRef, useState, useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import "./profilePage.css";
import RemoveIcon from "../../images/delete.svg";
import ProfileIcon from "../../images/profileIcon-cropped.svg";
import EditIcon from "../../images/camera-icon.svg";
import ChangeIcon from "../../images/changeIcon.svg";
import ChevronLeft from "../../images/chevron-left-solid.svg";
import SettingsIcon from "../../images/settings-icon.svg";
import NextButton from "../../images/right-arrow-button.svg";
import { Auth } from 'aws-amplify';
import OtpInput from 'react-otp-input';
import Dropdowns from './Dropdowns'


export function ProfilePage(props) {
    const location = useLocation();
    const comingSoon = false;
    const soon = useRef();
    const profilePicDialogRef = useRef(null);

    const confirmSettingRef = useRef(null);
    const forgotPasswordRef = useRef(null);
    const [profilePic, setProfilePic] = useState(ProfileIcon);
    const [registeredZipcode, setRegisteredZipcode] = useState('');
    const [registeredCountry, setRegisteredCountry] = useState('');
    const [registeredCity, SetRegisteredCity] = useState('');
    const [registeredLocation, setRegisteredLocation] = useState('');
    const [otp, setOtp] = useState("");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newResetPassword, setNewResetPassword] = useState("");
    const [confirmNewResetPassword, setConfirmNewResetPassword] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const [forgotError, setForgotError] = useState('');
    const [activePrimaryLocation, setActivePrimaryLocation] = useState({}) //activePrimaryLocation is an object that contains the location details of the primary location
    const [activePrimaryLocationString, setActivePrimaryLocationString] = useState("")

    const toggleDialog = (dialogRef) => {
        if (!dialogRef.current) {
            console.log('dialogRef is null');
            return;
        }
        if (dialogRef.current.hasAttribute('open')) {
            console.log('closing dialog')   
            dialogRef.current.close() 
            
        } else {
            console.log('opening dialog')
            dialogRef.current.showModal();
        }
    };

    const swapDialogs = () => {
        if (confirmSettingRef.current.hasAttribute('open')) 
        {
            confirmSettingRef.current.close();
            forgotPasswordRef.current.showModal();
        }
        else if (forgotPasswordRef.current.hasAttribute('open'))
        {
            forgotPasswordRef.current.close();
            confirmSettingRef.current.showModal();
        }
    };
    

    
    const {parentPath, tokenPayload, communityHealthInfo,
            options,
            countyData,
            countyLabel,
            stateData,
            stateLabel,
            countryData,
            countryLabel,
            comingSoonOptions,
            headerTitle,
            minuteCardOneHeader,
            minuteCardOneText,
            minuteCardTwoHeader,
            minuteCardTwoText,
            sayComponentText,
            saySectionLabel,
            newNavPage,
            dropdownOpen,
            navPage
        } = location.state || {};
    const [userInfo, setUserInfo] = useState();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);



    const getRegisteredLocation = async () => {
        try {
            const res = await axios.get(`https://ehomoi5b95.execute-api.us-east-1.amazonaws.com/dev/zipcode/getPlaceByCityNameAndCountryCodeAndZipcode`, {
                params: { cityName: registeredCity, zipcode: registeredZipcode, countryCode: registeredCountry },
            });
            console.log(res.data);
            const location = res.data.place;
            setRegisteredLocation(`${registeredZipcode}, ${location.pn ? location.pn + ',' : ''}${location.adm3 ? location.adm3 + ',' : ''} ${location.adm2 ? location.adm2 + ',' : ''} ${location.adm1 ? location.adm1 + ',' : ''} ${registeredCountry.toUpperCase()}`);
        }
        catch (error) {
            console.error(error);
        }
    };

    const navigate = useNavigate();
    useEffect(() => {
        fetchUserInfo();
        console.log("location.state: "+JSON.stringify(location.state))
    }, [location.state])
    useEffect(()=>{
        const menuTop=document?.querySelector(".returnMainMenu");
        menuTop.scrollIntoView({behavior:"instant"});
    },[])

    const fetchUserInfo = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setUserInfo(user);
            setRegisteredZipcode(user.attributes['custom:Zipcode']);
            setRegisteredCountry(user.attributes['custom:Country']);
            SetRegisteredCity(user.attributes['custom:City']);
            console.log(user.attributes['custom:Zipcode']); // Log directly from the user object
        } catch (error) {
            console.error('Error fetching user information', error);
        }
    };

    useEffect(() => {
        getRegisteredLocation();
    }, [ /*registeredZipcode, registeredCountry,*/ registeredCity]);

    // useEffect(() => {
    //     retrieveUserInfo(tokenPayload.sub)
    // }, [])

    const DynamicTextInput = ({label, name, initialValue}) => {
        const submitInput = useRef();
        const [value, setValue] = useState(initialValue);
        var placeholderVal = "";
        // Displays correct placeholder value in accordance with field
        // Placeholder is current user info, can be edited.
        if (userInfo !== undefined && userInfo != null){
            if(name === "name") {placeholderVal = tokenPayload.email;}
            if(name === "userID") {placeholderVal = userInfo.userAlias;}
            if(name === "zipcode") {placeholderVal = tokenPayload['custom:Zipcode'];}
        }

        return (
            <div className={`${name}InputSection`}>
                <form className="inputForm" onSubmit={async (e) => {
                    e.preventDefault();
                    await axios.post("https://vfwkkdrn7k.execute-api.us-east-1.amazonaws.com/default/update-user-info", {
                        "userID": `${tokenPayload.sub}`, "infoField": name, "infoValue": e.target[0].value
                    }).then((res) => {
                        alert("Update Successful!");
                    }).catch((err) => {
                        alert("Update Error: please try again.")
                        console.log(err);
                    })
                }}>
                    <label className="nameLabel">
                        {label}
                        <input type="text" value={value} className={`${name}Input`} placeholder={placeholderVal} ref={submitInput} 
                        onChange={(e) => {
                            setValue(e.target.value)
                        }} />
                    </label>
                    <button type="submit" className="submitButton"></button>
                    <hr className="formPlaceholderLine"/>
                </form>
            </div>
        )
    }

    const handleNavigation = (toExpand) => {
        navigate(parentPath, 
            {
                state: {
                    dropdownOpenState:dropdownOpen.footer?{footer:true,expand:toExpand}:{footer:false,expand:toExpand}, 
                    options:options, 
                    communityHealthInfo,
                    countyData,
                    countyLabel,
                    stateData,
                    stateLabel,
                    countryData,
                    countryLabel,
                    comingSoonOptions,
                    headerTitle,
                    minuteCardOneHeader,
                    minuteCardOneText,
                    minuteCardTwoHeader,
                    minuteCardTwoText,
                    sayComponentText,
                    saySectionLabel,
                    newNavPage,
                    tokenPayload,
                    navPage
                }
            }
        )
    }

    const handleResetPassword = async () => {
        if (newResetPassword !== confirmNewResetPassword) {
            console.error('Passwords do not match');
            
            return;
        }
        else {
            try {
                await Auth.forgotPasswordSubmit(userInfo?.attributes.email, otp, newResetPassword);
                console.log('Password reset successfully');
                window.location.reload();
            } catch (error) {
                console.error('Error resetting password:', error);
                setForgotError('Error: please check your verification code and try another password');
            }
        }
    };

    const handleConfirmPassword = async () => {
        try {
            await Auth.signIn(userInfo.username, confirmPassword);
            console.log('Password is correct');
            handleInputSubmit();
        }
        catch (error) {
            console.error('Error with validating password:', error);
            setConfirmError('Error: incorrect password');
        }
    };

    const handleInputSubmit = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log(user);
            
            await Auth.updateUserAttributes(user, {
                'custom:Zipcode': activePrimaryLocationString.split(',')[0].trim(),
                'custom:Country': activePrimaryLocation.cc,
                'custom:City': activePrimaryLocation.pn,
            });
            console.log('User information updated successfully');
            toggleDialog(confirmSettingRef);
            window.location.reload();
        } catch (error) {
            console.error('Error updating user information', error);
        }
    
    };

    const handleSendResetCode = async () => {
        try {
            await Auth.forgotPassword(userInfo?.attributes.email);
            console.log('Reset code sent to email');
            swapDialogs();
        } catch (error) {
            console.error('Error sending reset code:', error);
            setForgotError(error.message || 'Error with sending reset code.');
        }
    };

    



    // // Queries DynamoDB to get the current user's information
    // const retrieveUserInfo = async () =>{
    //     await axios.post("https://vfwkkdrn7k.execute-api.us-east-1.amazonaws.com/default/retrieve-user-info", {
    //         "userID": `${tokenPayload.sub}`
    //     }).then((res) => {
    //         setUserInfo(res.data.Item);
    //     }).catch((err) => {
    //         console.log(err);
    //     })
    // }

    //Handles user inputted file
    const changeProfilePic = (e) => {
        const newImage = e.target.files[0];
        if (newImage) {
            const newImageUrl = URL.createObjectURL(newImage);
            setProfilePic(newImageUrl);
        }
    }

    const removeProfilePic = (e) => {
        setProfilePic(ProfileIcon);
    }

    // //Opens the profile picture menu
    // const toggleDialog = () => {
    //     if (!dialogRef.current) {
    //         return;
    //     }
    //     dialogRef.current.hasAttribute('open') 
    //     ? dialogRef.current.close() 
    //     : dialogRef.current.showModal();
    //  }

    //  //Closes the profile picture menu
    // const closeDialog = () => {
    //     if (dialogRef.current) {
    //         dialogRef.current.close();
    //     }
    // }



    const getExactLocation = async (zipcode, city, country) => {
        try {
            const res = await axios.get(`https://ehomoi5b95.execute-api.us-east-1.amazonaws.com/dev/zipcode/getPlaceByCityNameAndCountryCodeAndZipcode`, {
                params: { cityName: city, zipcode: zipcode, countryCode: country },
            });
            console.log(res.data);
            const location = res.data.place;
            return `${zipcode}, ${location.pn ? location.pn + ',' : ''}${location.adm3 ? location.adm3 + ',' : ''} ${location.adm2 ? location.adm2 + ',' : ''} ${location.adm1 ? location.adm1 + ',' : ''} ${country.toUpperCase()}`;
        }
        catch (error) {
            console.error(error);
            return "";
        }
    };
    return( 
        <div className="profilePage">
            <dialog ref={forgotPasswordRef} className='forgotPasswordDialog'>
                <div className="goBackContainer">
                    <button className="goBackButton" onClick={() => {swapDialogs();}}>
                        <img src={ChevronLeft} alt="chevron" id='backChevron'/>
                    </button>
                    <button className="closeDialogButton" id='closeConfirmation' onClick={() => {toggleDialog(forgotPasswordRef);}}>
                        <p>-</p>
                    </button>
                </div>
                <p className='forgotPassText'>Check your email — enter your verification code and new password</p>
                <div className='forgotPassInputContainer'>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                        containerStyle="otpInputContainer"
                        inputStyle="otpInput"
                    />
                    <input className='newPassword' type='password' placeholder='new password' onChange={(e) => setNewResetPassword(e.target.value)}></input>
                    <input className='newPassword' id='confirmPassword' type='password' placeholder='confirm password' onChange={(e) => setConfirmNewResetPassword(e.target.value)}></input>
                    <button className='confirmSettingButton' disabled={!otp||!newResetPassword||!confirmNewResetPassword||(newResetPassword!=confirmNewResetPassword)} onClick={handleResetPassword}>
                        <img src={NextButton} alt="next" className='nextButton'/>
                    </button>
                </div>
                <p className='forgotEmailText'>Forgot your email? <br/> Contact support@civilience.social </p>
                {forgotError && <div style={{ color: 'red', marginTop: '10px' }}>{forgotError}</div>}
            </dialog>
            <dialog ref={confirmSettingRef} className="confirmSettingDialog" >
                <div id="closeDialog">
                    <button className="closeDialogButton" id='closeConfirmation' onClick={() => {toggleDialog(confirmSettingRef)}}>
                        <p>-</p>
                    </button>
                </div>
                <p className="confirmSettingText">Enter your password to confirm setting changes</p>
                <input className='passwordBox' type='password' placeholder='password' onChange={(e) => setConfirmPassword(e.target.value)}></input>
                <button className='confirmSettingButton' disabled={!confirmPassword} onClick={handleConfirmPassword}>
                    <img src={NextButton} alt="next" className='nextButton'/>
                </button>
                <button style={{all: 'unset', color: '#00286E', fontWeight: '500', cursor: 'pointer'}} className='forgotPassword' onClick={() => {handleSendResetCode();}}>Forgot your password?</button>
                {confirmError && <div style={{ color: 'red', marginTop: '10px' }}>{confirmError}</div>}
            </dialog>
            <dialog ref={profilePicDialogRef} className="profilePicDialog">
                <div id="closeDialog">
                    <button className="closeDialogButton" onClick={() => toggleDialog(profilePicDialogRef)}>
                        <p>-</p>
                    </button>
                </div>
                <div className="profileIconContainer">
                    <img src={profilePic} alt="profile" className="profileIcon" id="dialogProfilePic"/>
                </div>
                <div className="dialogButtonContainer">
                    <label htmlFor="input-file" className="dialogButtons" id="changeButton">
                            <img src={ChangeIcon} alt="change profile picture" className="changeIcon"></img>
                            Change
                    </label>
                    {/* no backend functionality yet for profile pic submission/deletion */}
                    <input type="file" accept="image/jpeg, image/png, image/jpg" id="input-file" onChange={changeProfilePic}></input>    
                    <button className="dialogButtons" id="removeButton" onClick={removeProfilePic}>
                        <img src={RemoveIcon} alt="remove picture" className="removeIcon"/>
                        Remove
                    </button>
                </div>
            </dialog>
            <div className="returnMainMenu">
                <div className="returnMainMenuSection">
                    <button onClick={() => {handleNavigation(true)}} className="returnMainMenuText">
                        <img src={ChevronLeft} alt="chevron" className="chevronLeft"/> 
                    </button>
                    <button className="closeMenu" onClick={() => {handleNavigation(false)}}><p>-</p></button>
                </div>
                
                <hr className="profileDivider"/>
            </div>
            <div className="profileTop">
                <div className="profileTitle">
                    <h1 className="profileHeader">PROFILE</h1>
                </div>
                <div className="profileIconContainer">
                    <img src={profilePic} alt="profile" className="profileIcon"/>
                    <img src={EditIcon} alt="edit" className="editIcon" onClick={() => toggleDialog(profilePicDialogRef)}/>
                </div>
                {/* username section renders only if user has a username */}
                {userInfo?.attributes.preferred_username ? (
                    <div className="usernameSection">
                        <span className="username">@{userInfo?.attributes.preferred_username}</span>
                    </div>
                ) : null}
                
            </div>
            <div className="profileMiddle">
                <hr className="profileDivider"/>
                
                {/* <label className="emailLabel">Email</label>
                <h6 className="userEmail">{tokenPayload.email}</h6>
                <hr className="emailPlaceholderLine" />
                <DynamicTextInput label="ZIP Code" name="zipcode" initialValue="" /> */}
                <div className="profileMiddleDetails">
                    <h6 className="profileMiddleDetails-label">Email</h6>
                    <hr className="middleHrLine" />
                    <h6 className="profileMiddleDetail-output">{userInfo?.attributes.email}</h6>
                </div>
                <div className="profileMiddleDetails" id="locationContainer">
                    <div className="profileLocationContainer">
                        <h6 className="profileMiddleDetails-label">Registered</h6>
                        <h6 className="profileMiddleDetails-label" id="locationLabel">Location</h6>
                    </div>
                    <hr className="middleHrLine" />
                    <div className="locationDetailContainer">
                        <h6 className="profileMiddleDetail-output" id="locationDetailOutput">{registeredLocation ? registeredLocation : 'Loading...'}</h6>
                    </div>
                </div>
            </div>
            <hr className="profileDivider"/>
            <div className="profileBottom">
            <Dropdowns
                    confirmSettingRef={confirmSettingRef}
                    tokenPayload={tokenPayload}
                    registeredLocation={registeredLocation}
                    toggleDialog={toggleDialog}
                    activePrimaryLocation={activePrimaryLocation}
                    activePrimaryLocationString={activePrimaryLocationString}
                    setActivePrimaryLocation={setActivePrimaryLocation}
                    setActivePrimaryLocationString={setActivePrimaryLocationString}
            />
            </div>
            <div className="settingsAndPrivacy">
            <hr className="profileDivider"/>
            <button 
                    onClick={(e) => {
                        e.preventDefault();
                        //display coming soon
                        }}
                    onMouseOver={(e) => {
                        e.preventDefault();
                        console.log("mouseover");
                        }
                    }
                className="settingsButton">
                    <div className="settingsSection">
                        <img src={SettingsIcon} alt="settings" className="settingsIcon"/>
                        <Link state={{tokenPayload, parentPath, communityHealthInfo,
                            options,
                            countyData,
                            countyLabel,
                            stateData,
                            stateLabel,
                            countryData,
                            countryLabel,
                            comingSoonOptions,
                            headerTitle,
                            minuteCardOneHeader,
                            minuteCardOneText,
                            minuteCardTwoHeader,
                            minuteCardTwoText,
                            sayComponentText,
                            saySectionLabel,
                            newNavPage,
                            dropdownOpen}} to={"/profile/settings"} className="profileSettings"><h2 className="settingsText">Settings and Privacy</h2></Link>
                    </div>
                </button>
            </div>
        </div>
    )

}